import React, { useRef, useEffect } from 'react';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import VideoPlaceholder from '../VideoPlaceholder';
import RichText from '../RichText';
import { Button } from '../Button';
import { withPrefix } from 'gatsby';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

function MediaHeroProducts({ title, ctaText, ctaLink, bodyText, asset, videoCoverImage, heroBg }) {
  const placeholderPosterFluid = videoCoverImage?.fluid;
  const imageFluid = asset?.fluid;
  const videoSrc = asset?.file?.url;
  const heroBgImage = heroBg?.file.url;

  return (
    <section className="media-hero-products">
      <div
        style={{
          backgroundImage: `url(${heroBgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
          backgroundRepeat: 'no-repeat',
          minHeight: '420px'

        }}
        className="media-hero-products__container">
          <div className="media-hero-products__inner">
        <div className="media-hero-products__content">
          <h1 className="media-hero__title">{title}</h1>
          <div className="media-hero__body">
            <RichText source={bodyText.json} />
          </div>
          <div className="media-hero__cta">    
            {ctaText && (
              <Button theme="dark" href={ctaLink}>{ctaText}</Button> 
            )}
          </div>
        </div>
        {/* <div className="media-hero__media">
          <div className="media-hero__media-inner">
            {imageFluid ? (
              <Image fluid={imageFluid} />
            ) : (
              <VideoPlaceholder
                posterFluid={placeholderPosterFluid}
                src={videoSrc}
              />
            )}
          </div>
        </div> */}
        </div>
      </div>
    </section>
  );
}

MediaHeroProducts.defaultProps = {
  title: 'Lorem ipsum dolor sit amet',
  ctaText: 'Request demo',
  ctaLink: '#request-demo',
  bodyText:
    'Duis ligula magna, egestas ac ipsum sit amet, mattis sodales nulla. Duis iaculis consectetur iaculis. Mauris ultricies et nisi vitae tempor.',
  videoCoverImage: {
    fluid: {
      src: '',
    },
  },
  asset: {
    file: {
      url: '',
    },
  },
  heroBg: {
    fluid: {
      url: '',
    },
  },
};

export default MediaHeroProducts;

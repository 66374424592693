import React, { Fragment } from 'react';
import { Form } from './Form';
import TextWithForm from '../../components/TextWithForm';

const FormSectionWithText = (props) => {
  const {
    __typename,
    textWithFormTitle,
    textWithFormDescription,
    listItemTitle1,
    listItemDescription1,
    listItemTitle2,
    listItemDescription2,
    listItemTitle3,
    listItemDescription3,
    listItemTitle4,
    listItemDescription4,
    listItemTitle5,
    listItemDescription5,
    listItemTitle6,
    listItemDescription6,
    embeddedForm,
  } = props;

  const lineItems = [];

  if (listItemTitle1)
    lineItems.push({
      heading: listItemTitle1,
      description: listItemDescription1?.listItemDescription1,
    });
  if (listItemTitle2)
    lineItems.push({
      heading: listItemTitle2,
      description: listItemDescription2?.listItemDescription2,
    });
  if (listItemTitle3)
    lineItems.push({
      heading: listItemTitle3,
      description: listItemDescription3?.listItemDescription3,
    });
  if (listItemTitle4)
    lineItems.push({
      heading: listItemTitle4,
      description: listItemDescription4?.listItemDescription4,
    });
  if (listItemTitle5)
    lineItems.push({
      heading: listItemTitle5,
      description: listItemDescription5?.listItemDescription5,
    });
  if (listItemTitle6)
    lineItems.push({
      heading: listItemTitle6,
      description: listItemDescription6?.listItemDescription6,
    });

  return (
      <section className="form-section-with-text">
        <TextWithForm
            textWithFormTitle={textWithFormTitle}
            textWithFormDescription={
              textWithFormDescription?.textWithFormDescription
            }
            lineItems={lineItems}
            formData={embeddedForm}
        />
      </section>
  );
};

export default FormSectionWithText;

import React from 'react';
import { Form } from '../../components/Form';
import styled from 'styled-components';
import { useCookie } from 'react-use';
import SectionIntro from '../SectionIntro';

const LineItem = ({ heading, description }) => (
    <div className="text-with-form__line-item line-item">
      <div className="line-item__icon">
        <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[19px] w-[23px]">
          <path d="M2.13945 10.9328L1.50792 11.6659L2.21975 12.3212L7.14224 16.8532L7.84497 17.5001L8.52345 16.8278L20.8068 4.65536L21.4931 3.97529L20.8371 3.26603L20.4661 2.86492L19.7632 2.10507L19.028 2.83365L7.78083 13.9798L3.93209 10.435L3.17173 9.73464L2.49701 10.5178L2.13945 10.9328Z" fill="black" stroke="black" strokeWidth="2"></path>
        </svg>
      </div>
      <div>
        <p className="line-item__heading">{heading}</p>
        {description && (
            <p className="line-item__description">
              {description}
            </p>
        )}
      </div>
    </div>
);

const TextWithForm = ({
  textWithFormTitle,
  textWithFormDescription,
  lineItems,
  footer,
  formData,
  ...props
}) => {
  const COOKIE_NAME = 'PWC_WF_USER_GATE';
  const COOKIE_VALUE_UNGATED = '1';
  // eslint-disable-next-line no-unused-vars
  const [ value, updateCookie, deleteCookie ] = useCookie(COOKIE_NAME);

  const handleFormSubmit = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
  };

  const RedirectURL = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
    if (typeof window !== 'undefined') {
      window.location = '/demo/thank-you';
    }
    return null;
  };

  return (
      <div className="text-with-form">
        <div className="text-with-form__inner-container">
          <div className="text-with-form__column text-with-form__column--text">
            <SectionIntro title={textWithFormTitle} subtitle={textWithFormDescription}/>
            {lineItems && (
                <div className="line-items">
                  {lineItems.map((lineItem, index) => (
                      <LineItem
                          key={index}
                          heading={lineItem?.heading}
                          description={lineItem?.description}
                          spaced={index === 0}
                      />
                  ))}
                </div>
            )}
            {footer}
          </div>
          <div id="request-demo" className="text-with-form__column text-with-form__column--form">
            <Form onSubmitSuccess={() => RedirectURL()} {...formData} />
            <p class="privacy-notice">By submitting your email address, you acknowledge that you have read the <a href="https://www.pwc.com/us/en/site/privacy.html" target="_blank">Privacy Statement</a>.</p>
          </div>
        </div>
      </div>
  );
};

export default TextWithForm;

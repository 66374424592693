import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import FormSectionWithText from '../components/Form/FormSectionWithText';
import MediaHeroProducts from '../components/MediaHeroProducts';
import DeviceSection from '../components/DeviceSection';
import DemoBanner from '../components/DemoBanner';
import Testimonial from '../components/Testimonial';
import SideBySideSection from '../components/SideBySideSection';
import ResourcesSection from '../components/ResourcesSection';
import BenefitsSection from '../components/BenefitsSection';
import { deviceDataFromDeviceAsset } from '../utils/contentfulHelpers';
import SiteMeta from '../components/SiteMeta';
import { useCookie } from 'react-use';
import CurrentPromotionsSection from '../components/CurrentPromotionsSection';

const ProductsPage = ({ data }) => {
  const {
    metaTitle,
    slug,
    metaDescription: { metaDescription },
    heroModule,
    productIntroModule,
    productValuePropModule,
    currentPromotions,
    topics,
    benefits,
    productUseCaseModule,
    productFeaturesModule,
    // testimonial,
    demoCallout,
    featuredResourcesTitle,
    featuredResourcesSubheaderText: { featuredResourcesSubheaderText },
    featuredResourcesCtaLink,
    featuredResourcesCtaText,
    featuredResources,
    formSection
  } = data.contentfulWfeProductsDetail;

  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheaderText,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources,
  };

  const productValuePropDeviceData = deviceDataFromDeviceAsset(productValuePropModule.deviceAsset);
  const productFeaturesDeviceData = deviceDataFromDeviceAsset(productFeaturesModule.deviceAsset);

  // console.log('Current Promotions', currentPromotions);
  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [ value, updateCookie, deleteCookie ] = useCookie(COOKIE_NAME);

  const RedirectURL = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
    if (typeof window !== 'undefined') {
      window.location = '/demo/thank-you';
    }
    return null;
  };
  return (
      <Layout>
        <SiteMeta title={metaTitle} description={metaDescription}/>
        <MediaHeroProducts {...heroModule}/>
        {/* <CurrentPromotionsSection {...currentPromotions}/> */}
        <SideBySideSection {...productIntroModule} backgroundTheme="grey" barTheme="transparent"/>
        
        {/* <SideBySideSection {...topics} backgroundTheme="grey" barTheme="transparent"/> */}
        {benefits && (
         <BenefitsSection {...benefits}/>
        )}
        {/* <BenefitsSection {...benefits}/> */}
        <DeviceSection {...productValuePropModule} layoutVariant="deviceRight"
                       backgroundTheme="grey" barTheme="transparent"
                       {...productValuePropDeviceData}/>
        <SideBySideSection {...productUseCaseModule} backgroundTheme="white" barTheme="transparent"/>
        <DeviceSection {...productFeaturesModule} layoutVariant="deviceRight"
                       backgroundTheme="grey" barTheme="transparent"
                       {...productFeaturesDeviceData}/>
        {/* <Testimonial {...testimonial}/> */}
        {formSection
        ? <FormSectionWithText {...formSection} /> 
        : <DemoBanner {...demoCallout} productSlug={slug} theme="yellow"/>
        }
        <ResourcesSection {...resourcesData}/>
      </Layout>
  );
};

export default ProductsPage;

export const query = graphql`
  query ProductsPageQuery($contentful_id : String!) {
    contentfulWfeProductsDetail(contentful_id : { eq: $contentful_id  }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      slug
      heroModule {
        ...Navigator
      }
      productIntroModule {
        ...Navigator
      }
      productValuePropModule {
        ...DeviceNavigator
      }
      currentPromotions {
        ...Navigator
      }
      topics {
        ...Navigator
      }
      benefits {
        ...CardWithImage
        ...TwoColumnCard
      }
      productUseCaseModule {
        ...Navigator
      }
      productFeaturesModule {
        ...DeviceNavigator
      }
      demoCallout {
        ...Navigator
      }
      featuredResources {
        ...ResourceCard
      }
      featuredResourcesCtaText
      featuredResourcesTitle
      featuredResourcesCtaLink
      featuredResourcesSubheaderText {
        featuredResourcesSubheaderText
      }
      formSection {
        ...TextWithForm
      }
    }
  }
`;
